body {
 color: black;  
font-family: 'Open Sans', sans-serif;
letter-spacing: 0.05rem;
}
h1 {
    /*font-family: 'Dancing Script', cursive;*/
    line-height: 1,5em;
   text-shadow: 1px 1px 2px rgb(7, 7, 7);
}




p {
  font-family: 'Open Sans', sans-serif;
    font-style: italic;
    
}
 .btn {
   background-color: #547317;
   color: whitesmoke;
 }
 .btn:hover {
  background-color: #2f4308;
}

#logo {
  width: 30px;
  margin-right: 15px;
}

.lang_img {
  width: 30px;
}


 /* section unde si cand*/
#geolocal {
  background-image: linear-gradient(90deg, #547317 , #E9EC96 95%);
}
#geolocal h1 {
  color: aliceblue;
}

  /* section unde si cand*/



  /* css blog*/

.main-row {
 
    margin-bottom: 8%;
    background-color:rgba(255, 255, 255, 0.705);
    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    border-radius: 0.5rem;
    
  }
  
  .blog-img > img {
   width: 250px;
   transform: translateY(-30px);
   border-radius: 50%;
   box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
  }
  .blog-title > h3 {
    line-height: 2rem;
  }
  @media (min-width: 500px) {
  .blog-title > h3 {
    line-height: 3rem;
  }
 
  }
  @media (min-width: 1100px) {
    .main-row {
      max-width: 800px;
      position: relative;
      left: 15%;
    }
    }

    .rama {
      width: 250px;
      transform: translateY(-30px);
      border-radius: 50%;
      box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
    }
  .container-img span{
    display: inline-block;
  }
    .music {
      width: 80px;
    }